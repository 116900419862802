.dkcharts-gauge {
    position:relative;

    .highcharts-pane {
        fill-opacity: 0.2;
    }

    .highcharts-title {
        letter-spacing: -0.2ex;
        text-align: center;
        font-family: $font-family;
        color: $text-color-1;
        position:absolute;

    }
    .highcharts-subtitle {
        position: absolute;
        text-align: center;
        font-family: $font-family;
        color: $text-color-2;
    }
}

.theme-dark .dkcharts-gauge {
    .highcharts-pane {
        fill-opacity: 0.75;
    }
}
