/* Default theme, changed to using css variables
 */
.dkcharts {

    // Colors for data series and points.
    // NOTE: you don't want any of these colors to be too close to the background color
    --data-color-1: #7cb5ec;
    --data-color-2: #434348;
    --data-color-3: #90ed7d;
    --data-color-4: #f7a35c;
    --data-color-5: #8085e9;
    --data-color-6: #f15c80;
    --data-color-7: #e4d354;
    --data-color-8: #2b908f;
    --data-color-9: #f45b5b;
    --data-color-10: #91e8e1;

    // Chart background, point stroke for markers and columns etc
    --background-color: #ffffff;

    // NOTE: the neutrals should go from furthest away from background-color, i.e.
    // if the background color is white (#ffffff) then --neutral-color-100 could
    // be black (#000000).

    // Neutral colors, grayscale by default. The default colors are defined by mixing the
    // background-color with neutral, with a weight corresponding to the number in the name.
    --neutral-color-100: #000000;        // Strong text.
    --neutral-color-80: #333333;         // Main text and some strokes.
    --neutral-color-60: #666666;         // Axis labels, axis title, connector fallback.
    --neutral-color-40: #999999;         // Credits text, export menu stroke.
    --neutral-color-20: #cccccc;         // Disabled texts, button strokes, crosshair etc.
    --neutral-color-10: #e6e6e6;         // Grid lines etc.
    --neutral-color-5: #f2f2f2;          // Minor grid lines etc.
    --neutral-color-3: #f7f7f7;          // Tooltip backgroud, button fills, map null points.

    // Colored, shades of blue by default
    --highlight-color-100: #003399;     // Drilldown clickable labels, color axis max color.
    --highlight-color-80: #335cad;      // Selection marker, menu hover, button hover, chart border, navigator series.
    --highlight-color-60: #6685c2;      // Navigator mask fill.
    --highlight-color-20: #ccd6eb;      // Ticks and axis line.
    --highlight-color-10: #e6ebf5;      // Pressed button, color axis min color.

    // Fonts
    --font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    --title-font-size: 1.5em;
    --subtitle-font-size: 1em;
    --legend-font-size: 1em;
    --axis-labels-font-size: 0.9em;

    // Tooltip
    --tooltip-border: 1px;
    --tooltip-background: var(--neutral-color-3);

    // Axes
    --xaxis-grid-line: 0px;

    // Range-selector
    --range-selector-button-border: 0px;
    --range-selector-input-text: var(--neutral-color-80);
    --range-selector-input-border: var(--neutral-color-20);

    // Data-labels
    --data-label-color: var(--neutral-color-80);

    // Buttons
    --context-button-background: var(--background-color);

    --highcharts-button-background: var(--neutral-color-3);
    --highcharts-button-border: var(--neutral-color-20);
    --highcharts-button-text:  var(--neutral-color-80);

    --highcharts-button-pressed-background: var(--highlight-color-10);
    --highcharts-button-pressed-border: var(--neutral-color-20);
    --highcharts-button-pressed-text:  var(--neutral-color-80);

    --highcharts-button-hover-background: var(--neutral-color-10);
    --highcharts-button-hover-border: var(--neutral-color-20);
    --highcharts-button-hover-text:  var(--neutral-color-80);

    // Navigator
    --navigator-series-fill: var(--highlight-color-80);
    --navigator-series-border: var(--highlight-color-80);

    // Scrollbar
    --scrollbar-track-background: var(--neutral-color-5);
    --scrollbar-track-border: var(--neutral-color-5);

}
