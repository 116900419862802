@import "theme-vars";

.theme-dark {
    // Chart background, point stroke for markers and columns etc
    --background-color: #666666;

    --data-color-2: #54e4c2;    // default was too close to background color

    // [bp] the neutrals should go from furthest away from background-color.
    // Neutral colors, grayscale by default. The default colors are defined by mixing the
    // background-color with neutral, with a weight corresponding to the number in the name.
    --neutral-color-100: #f7f7f7;        // Strong text.
    --neutral-color-80: #f2f2f2;         // Main text and some strokes.
    --neutral-color-60: #e6e6e6;         // Axis labels, axis title, connector fallback.
    --neutral-color-40: #cccccc;         // Credits text, export menu stroke.
    --neutral-color-20: #999999;         // Disabled texts, button strokes, crosshair etc.
    --neutral-color-10: #777777;         // Grid lines etc.
    --neutral-color-5: #333333;          // Minor grid lines etc.
    --neutral-color-3: #000000;          // Tooltip backgroud, button fills, map null points.


    --tooltip-background: var(--neutral-color-3);

    .highcharts-tooltip {
        text, span {
            fill: $text-color-0;
            color: $text-color-0;
        }
    }
}
