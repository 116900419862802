@import "theme-vars";

.highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: $font-family;
    font-size: 12px;
}

.highcharts-root {
    display: block;
}

.highcharts-root text {
    stroke-width: 0;
}

.highcharts-strong {
    font-weight: bold;
}

.highcharts-emphasized {
    font-style: italic;
}

.highcharts-anchor {
    cursor: pointer;
}

.highcharts-background {
    fill: $background-color;
}

.highcharts-plot-border,
.highcharts-plot-background {
    fill: none;
}

.highcharts-label-box {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}

.highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
}

.highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
}

/* Titles */
.highcharts-title {
    fill: $neutral-color-80;
    font-size: $title-font-size;
}

.highcharts-subtitle {
    fill: $neutral-color-60;
}

/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: $highlight-color-20;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: $neutral-color-60;
}

.highcharts-axis-labels {
    fill: $neutral-color-60;
    cursor: default;
    font-size: $axis-labels-font-size;
}

.highcharts-grid-line {
    fill: none;
    stroke: $neutral-color-10;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: $xaxis-grid-line;
}

.highcharts-tick {
    stroke: $highlight-color-20;
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: $neutral-color-5;
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: $neutral-color-20;
}

.highcharts-crosshair-category {
    stroke: $highlight-color-20;
    stroke-opacity: 0.25;
}


/* Credits */
.highcharts-credits {
    cursor: pointer;
    fill: $neutral-color-40;
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
    fill: black;
    font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
}

.highcharts-tooltip text {
    fill: $neutral-color-80;
}

.highcharts-tooltip .highcharts-header {
    font-size: 0.85em;
}

.highcharts-tooltip-box {
    stroke-width: $tooltip-border;
    fill: $tooltip-background;
    fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
    fill: $tooltip-background;
    fill-opacity: 0.85;
}

.highcharts-selection-marker {
    fill: $highlight-color-80;
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
}

.highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */
}

.highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
    transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2;
}

/* Series options */

/* Default colors */
@for $i from 1 through length($colors) {
    $color: nth($colors, $i);

    .highcharts-color-#{$i - 1} {
        fill: $color;
        stroke: $color;
    }
}

.highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: $background-color;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
}

.highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
    fill: $data-label-color;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
    fill: $neutral-color-20;
    stroke: $neutral-color-100;
}

.highcharts-column-series rect.highcharts-point {
    // rect to prevent stroke on 3D columns
    stroke: $background-color;
}

.highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $background-color;
}

.highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $background-color;
}

.highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $background-color;
}

.highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: $neutral-color-10;
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
    stroke: $neutral-color-40;
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
    display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
    fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
}



/* Legend */
.highcharts-legend-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-legend-item text {
    fill: $neutral-color-80;
    font-weight: bold;
    font-size: $legend-font-size;
    cursor: pointer;
    stroke-width: 0;
}

.highcharts-legend-item:hover text {
    fill: $neutral-color-100;
}

.highcharts-legend-item-hidden * {
    fill: $neutral-color-20 !important;
    stroke: $neutral-color-20 !important;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: $highlight-color-100;
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: $neutral-color-20;
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}

/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: $background-color;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band,
.highcharts-pane {
    fill: $neutral-color-100;
    fill-opacity: 0.05;
}

.highcharts-plot-line {
    fill: none;
    stroke: $neutral-color-40;
    stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
    fill: $background-color;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: $neutral-color-100;
}

.highcharts-gauge-series .highcharts-data-label-box {
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
    fill: $neutral-color-100;
    stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: $neutral-color-80;
    stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1;
}

/* Highstock */
.highcharts-navigator-mask-outside {
    fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
    fill: $highlight-color-60;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: $neutral-color-20;
    fill: none;
}

.highcharts-navigator-handle {
    stroke: $neutral-color-20;
    fill: $neutral-color-5;
    cursor: ew-resize;
}

.highcharts-navigator-series {
    fill: $navigator-series-fill;
    stroke: $navigator-series-border;
}

.highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: $neutral-color-10;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: $neutral-color-40;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: $neutral-color-20;
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: $neutral-color-10;
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: $neutral-color-60;
}

.highcharts-scrollbar-rifles {
    stroke: $neutral-color-60;
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: $scrollbar-track-background;
    stroke: $scrollbar-track-border;
    stroke-width: 1px;
}

.highcharts-button {
    fill: $highcharts-button-background;
    stroke: $highcharts-button-border;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;
}

.highcharts-button text {
    fill: $highcharts-button-text;
}

.highcharts-button-hover {
    transition: fill 0ms;
    fill: $highcharts-button-hover-background;
    stroke: $highcharts-button-hover-border;
}

.highcharts-button-hover text {
    fill: $highcharts-button-hover-text;
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: $highcharts-button-pressed-background;
    stroke: $highcharts-button-pressed-border;
}

.highcharts-button-pressed text {
    fill: $highcharts-button-pressed-text;
    font-weight: bold;
}

.highcharts-button-disabled text {
    fill: $highcharts-button-text;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: $range-selector-button-border;
}

.highcharts-range-label rect {
    fill: none;
}

.highcharts-range-label text {
    fill: $neutral-color-60;
}

.highcharts-range-input rect {
    fill: none;
}

.highcharts-range-input text {
    fill: $range-selector-input-text;
}

.highcharts-range-input {
    stroke-width: 1px;
    stroke: $range-selector-input-border;
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
}

.highcharts-crosshair-label text {
    fill: $background-color;
    font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
}


.highcharts-candlestick-series .highcharts-point {
    stroke: $neutral-color-100;
    stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
    fill: $background-color;
}

.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: $neutral-color-40;
    fill: $background-color;
    transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: $neutral-color-100;
    fill: $highlight-color-20;
}

.highcharts-flags-series .highcharts-point text {
    fill: $neutral-color-100;
    font-size: 0.9em;
    font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: $neutral-color-20;
}

.highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: $neutral-color-40;
}

.highcharts-null-point {
    fill: $neutral-color-3;
}

/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
    fill: $context-button-background;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
}

.highcharts-contextbutton:hover {
    fill: $neutral-color-10;
    stroke: $neutral-color-10;
}

.highcharts-button-symbol {
    stroke: $neutral-color-60;
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid $neutral-color-40;
    background: $background-color;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: $neutral-color-80;
    cursor: pointer;
    transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
    background: $highlight-color-80;
    color: $background-color;
}

/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: $highlight-color-100;
    font-weight: bold;
    text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: $neutral-color-60;
}

/* Drag-panes module */
.highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
    stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: $neutral-color-80;
}

.highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: $neutral-color-80;
}

/* Annotations module */
.highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: $neutral-color-100;
    fill: $neutral-color-100;
    fill-opacity: 0.75;
}

.highcharts-annotation-label text {
    fill: $neutral-color-10;
}

/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
    cursor: pointer;
}

.highcharts-point-connecting-path {
    fill: none;
}

.highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
}