
// Text colors from most intense to least intense.
$text-color-0: var(--neutral-color-100) !default; // Strong text.
$text-color-1: var(--neutral-color-80) !default; // Main text and some strokes.
$text-color-2: var(--neutral-color-60) !default; // Axis labels, axis title, connector fallback.
$text-color-3: var(--neutral-color-40) !default; // Credits text, export menu stroke.
$text-color-4: var(--neutral-color-20) !default; // Disabled texts, button strokes, crosshair etc.


// Colors for data series and points.
$colors: var(--data-color-1) var(--data-color-2) var(--data-color-3) var(--data-color-4) var(--data-color-5) var(--data-color-6) var(--data-color-7) var(--data-color-8) var(--data-color-9) var(--data-color-10) !default;

// Chart background, point stroke for markers and columns etc
$background-color: var(--background-color) !default;

// Neutral colors, grayscale by default. The default colors are defined by mixing the
// background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: var(--neutral-color-100) !default; // Strong text.
$neutral-color-80: var(--neutral-color-80) !default; // Main text and some strokes.
$neutral-color-60: var(--neutral-color-60) !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: var(--neutral-color-40) !default; // Credits text, export menu stroke.
$neutral-color-20: var(--neutral-color-20) !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: var(--neutral-color-10) !default; // Grid lines etc.
$neutral-color-5: var(--neutral-color-5) !default; // Minor grid lines etc.
$neutral-color-3: var(--neutral-color-3) !default; // Tooltip backgroud, button fills, map null points.

// Colored, shades of blue by default
$highlight-color-100: var(--highlight-color-100) !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: var(--highlight-color-80) !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: var(--highlight-color-60) !default; // Navigator mask fill.
$highlight-color-20: var(--highlight-color-20) !default; // Ticks and axis line.
$highlight-color-10: var(--highlight-color-10) !default; // Pressed button, color axis min color.

// Fonts
$font-family: var(--font-family) !default;
$title-font-size: var(--title-font-size) !default;
$subtitle-font-size: var(--subtitle-font-size) !default;
$legend-font-size: var(--legend-font-size) !default;
$axis-labels-font-size: var(--axis-labels-font-size) !default;

// Tooltip
$tooltip-border: var(--tooltip-border) !default;
$tooltip-background: var(--tooltip-background) !default;

// Axes
$xaxis-grid-line: var(--xaxis-grid-line) !default;

// Range-selector
$range-selector-button-border: var(--range-selector-button-border) !default;
$range-selector-input-text: var(--range-selector-input-text) !default;
$range-selector-input-border: var(--range-selector-input-border) !default;

// Data-labels
$data-label-color: $text-color-2 !default;

// Buttons
$context-button-background: var(--context-button-background) !default;

$highcharts-button-background: var(--highcharts-button-background) !default;
$highcharts-button-border: var(--highcharts-button-border) !default;
$highcharts-button-text: var(--highcharts-button-text) !default;

$highcharts-button-pressed-background: var(--highcharts-button-pressed-background) !default;
$highcharts-button-pressed-border: var(--highcharts-button-pressed-border) !default;
$highcharts-button-pressed-text: var(--highcharts-button-pressed-text) !default;

$highcharts-button-hover-background: var(--highcharts-button-hover-background) !default;
$highcharts-button-hover-border: var(--highcharts-button-hover-border) !default;
$highcharts-button-hover-text: var(--highcharts-button-hover-text) !default;

// Navigator
$navigator-series-fill: var(--navigator-series-fill) !default;
$navigator-series-border: var(--navigator-series-border) !default;

// Scrollbar
$scrollbar-track-background: var(--scrollbar-track-background) !default;
$scrollbar-track-border: var(--scrollbar-track-border) !default;
